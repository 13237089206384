






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";
import makeSelectOptions from "@common/util/makeSelectOptions";
import productComboProductItemRoles from "@/constants/productComboProductItemRoles";

export default Vue.extend({
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market) {
      const options = {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          name: "Quản lý combo sản phẩm",
          urlEnabled: true,
          search: {},
          displayFields: {
            id: {
              text: "ID",
              hidden: true,
            },
            name: {
              text: "Tên",
              sortable: true,
            },
            mktName: {
              text: "Tên hiển thị",
              sortable: true,
            },
            stickerName: {
              text: "Tên in trên sticker",
              sortable: true,
            },
            mktPlatforms: {
              text: "Nền tảng MKT",
              options: {
                labels: true,
                labelItems(value) {
                  return value.name;
                },
              },
            },
            localPrice: {
              text: "Giá bán nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            optimizedLocalPrice: {
              text: "Giá bán nội địa MKT tối ưu",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            localShippingCost: userManager.checkRole(["kt", "vdl", "vd"]) && {
              text: "Chi phí ship nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            realLocalShippingCost: {
              text: "Chi phí ship nội địa thực",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            expectedRevenue: {
              text: "Doanh thu MKT",
              options: {
                preTransform(_, item) {
                  let value = (item.localPrice - item.localShippingCost) * market.exchangeRate;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            realExpectedRevenue: userManager.checkRole(["kt", "vdl", "vd"]) && {
              text: "Doanh thu MKT thực",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.localPrice - (item.realLocalShippingCost || item.localShippingCost || 0)) *
                    market.exchangeRate;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            description: {
              text: "Mô tả",
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          filters: {
            filters: {
              "productItems.productId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  required: true,
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productItems.belongsToProductGroupId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  required: true,
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (kết thúc)",
                    },
                  },
                },
              },
            },
          },
          topActionButtons: {
            import: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "productCombos",
                                  "import",
                                  {
                                    marketId: market._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("productCombos", `@/export`, findAllOptions);
                },
              },
            },
            insert: userManager.checkRole(["kt", "vdl", "vd"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "1000px",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "1000px",
                    },
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["kt"]) && {},
              updatePrice: userManager.checkRole(["kt", "vdl", "vd"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật giá",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật giá",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.sku || item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            newLocalPrice: item.localPrice,
                            newLocalShippingCost: item.localShippingCost,
                          });
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    newLocalPrice: {
                                      type: "number",
                                      attrs: {
                                        label: "Giá bán nội địa",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: market.currency,
                                        autofocus: true,
                                      },
                                    },
                                    newLocalShippingCost: {
                                      type: "number",
                                      attrs: {
                                        label: "Chi phí ship nội địa",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: market.currency,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "productCombos",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        localPrice: data.newLocalPrice,
                                        localShippingCost: data.newLocalShippingCost,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("productCombos", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.marketId = dataTable.context().$route.params.marketId;
              item.productGroupId = dataTable.context().$route.params.productGroupId;
              return await coreApiClient.call("productCombos", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "productCombos",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("productCombos", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
                ext: {
                  colLength: 12,
                },
              },
              mktName: {
                attrs: {
                  label: "Tên hiển thị",
                },
                ext: {
                  colLength: 6,
                },
              },
              stickerName: {
                attrs: {
                  label: "Tên in trên sticker",
                },
                ext: {
                  colLength: 6,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              localPrice: {
                type: "number",
                attrs: {
                  label: "Giá bán nội địa",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
                ext: {
                  colLength: 6,
                },
              },
              optimizedLocalPrice: {
                type: "number",
                attrs: {
                  label: "Giá bán nội địa MKT tối ưu",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
                ext: {
                  colLength: 6,
                },
              },
              localShippingCost: {
                type: "number",
                attrs: {
                  label: "Chi phí ship nội địa",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
                ext: {
                  colLength: 6,
                },
              },
              realLocalShippingCost: {
                type: "number",
                attrs: {
                  label: "Chi phí ship nội địa thực",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
                ext: {
                  colLength: 6,
                },
              },
              mktPlatformIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              mainProductGroupIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm chính",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              productItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Sản phẩm / Nhóm sản phẩm",
                  xOptions: {
                    content: {
                      itemLabel: (item, i) =>
                        (item.product && item.product.sku) ||
                        (item.productGroup && item.productGroup.sku) ||
                        `Sản phẩm / Nhóm sản phẩm ${i + 1}`,
                      itemProperties: {
                        count: {
                          type: "integer",
                          attrs: {
                            label: "Số lượng",
                            required: true,
                          },
                        },
                        product: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm",
                            "item-value": "_id",
                            "item-text": "sku",
                            "return-object": true,
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      search,
                                      limit,
                                      filters: [
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: market.countryId,
                                        },
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value: value && value._id,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                          ext: {
                            onInput(value, form) {
                              form.decouratedData.productId = value ? value._id : "";
                            },
                          },
                        },
                        productGroup: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Nhóm sản phẩm",
                            "item-value": "_id",
                            "item-text": "sku",
                            "return-object": true,
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("productGroups", "findAll", {
                                    payload: JSON.stringify({
                                      search,
                                      limit,
                                      filters: [
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: market.countryId,
                                        },
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value: value && value._id,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                          ext: {
                            onInput(value, form) {
                              form.decouratedData.productGroupId = value ? value._id : "";
                            },
                          },
                        },
                        role: {
                          type: "select",
                          attrs: {
                            label: "Vai trò",
                            items: makeSelectOptions(productComboProductItemRoles),
                            required: true,
                          },
                          ext: {
                            defaultValue: "main",
                          },
                        },
                        productStickerName: {
                          attrs: {
                            label: "Tên in trên sticker",
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 2,
                            rules: {
                              product(data) {
                                if (!data.product && !data.productGroup) {
                                  return false;
                                } else if (data.product && data.productGroup) {
                                  return false;
                                }
                                return true;
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "vdl", "vd"]) && {
              content: {
                fieldNames: [
                  "name",
                  "mktName",
                  "stickerName",
                  "description",
                  "localPrice",
                  "optimizedLocalPrice",
                  "localShippingCost",
                  "realLocalShippingCost",
                  "mktPlatformIds",
                  "mainProductGroupIds",
                  "productItems",
                ],
              },
            },
            editForm: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "vdl", "vd"]) && {
              content: {
                fieldNames: [
                  "name",
                  "mktName",
                  "stickerName",
                  "description",
                  "localPrice",
                  "optimizedLocalPrice",
                  "localShippingCost",
                  "realLocalShippingCost",
                  "mktPlatformIds",
                  "mainProductGroupIds",
                  "productItems",
                ],
              },
            },
            delete: userManager.checkRole(["kt"]) && {
              confirmDisplayField: "sku",
            },
          },
        },
      };
      return options;
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.market);
  },
});
